#map_container {
  position: relative;
  width: 100%;
  height: calc(100vh - 120px);
  overflow: hidden;
  min-height: 518px;

  #map {
    .leaflet-control-container {
      .leaflet-control {
        border: none;

        a {
          color: #154194;
          width: 32px;
          height: 32px;
          line-height: 32px;

          &:first-child {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          }

          &:last-child {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }
      }

      .leaflet-top.leaflet-left {
        top: 3px;
        left: 3px;
      }

      .leaflet-bottom.leaflet-left {
        bottom: 50%;
        left: 3px;
      }

      .leaflet-control-attribution {
        display: none;
      }

      & + a {
        display: none;
      }

      .leaflet-control-locate .leaflet-control-locate-location-arrow:before {
        font-family: 'Font Awesome 6 Pro', sans-serif;
        content: "\f124";
        font-size: 20px;
      }
    }
  }
}

#map_menu_list {
  position: absolute;
  z-index: 99;
  top: 10px;
  left: 10px;
}

ul#map_menu {
  list-style: none;
  margin: 0;
  padding: 10px;
  height: 46px;
  position: relative;

  li:not(:first-child) {
    display: none;
  }
  .legend {
    display: none;
  }
}

ul#map_menu.expanded {
  li {
    display: flex;
  }
  .legend {
    display: flex;
    flex-direction: column;
    color: white;
    align-items: center;
    position: absolute;
    left: -4px;
    text-align: center;

    &.rt {
      top: 240px;
      line-height: 20px;
    }

    &.fc {
      top: 74px;
      line-height: 18px;
    }
  }
}

.layer_menu li {
  line-height: 1;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding: 3px;
  color: white;
  font-size: 11px;
}
ul#map_menu li {
  &:first-child {
    padding-top: 0;
    position: absolute;
    width: 240px;
  }

  &:nth-child(2) {
    margin-top: 56px;
  }
}

#map_menu button#toggle_map_menu {
  line-height: 32px;
  background-color: white;
  color: #00A6EE;
  border-radius: 25px;
  font-size: 22px;
  width: 240px;
  height: 52px;
  margin-left: -10px;
}

#map_menu button#toggle_map_menu img {
  width: 170px;
  margin: 0 5px;
}

#map_menu #toggle_map_menu > i:nth-child(2), #map_menu.expanded #toggle_map_menu > i:first-child {
  display: none;
}

#map_menu.expanded #toggle_map_menu > i:nth-child(2) {
  display: initial;
}

.layer_menu button {
  height: 32px;
  width: 32px;
  text-align: center;
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
}

.layer_menu button img {
  width: 32px;
  height: auto;
}

#map_menu button {
  margin-right: 10px;
}

#map_bg_menu button {
  margin-left: 10px;
}

#map_bg_menu{
  #legend{
    margin-top: 20px;
  }
  .legend{
    margin-top: 20px;
  }
}
.layer_menu span {
  display: none;
}

.layer_menu button:hover + span, .layer_menu button.active + span, #legend + span {
  display: initial;
}

.layer_menu button.active, .layer_menu button:hover {
  box-shadow: 0 0 0 3px #154194;
  border-radius: 50%;
}

#map_layers {
  position: absolute;
  bottom: 75px;
  left: 13px;
  width: 360px;

  z-index: 99;
  padding: 4px;
  border-radius: 12px;
  display: flex;
  column-gap: 4px;

  & > div {
    & > div {
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 8px;
      padding: 4px;
      display: flex;
      flex-wrap: wrap;
      column-gap: 4px;
    }

    &:first-child {
      flex: 0 0 180px;
    }

    &:last-child {
      flex: 0 0 180px;
    }

    p {
      text-align: center;
      font-size: 12px;
      margin: 0;
      flex: 0 0 100%;
      color: white;
    }

    button {
      height: 40px;
      width: 40px;
      border-radius: 4px;
      border: none;
      background-color: white;
      color: #154194;
      font-size: 18px;
      padding: 0;

      &.active {
        background-color: #154194;
        color: white;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &#fc_layers button {
      color: #00A6EE;

      &.active {
        background-color: #00A6EE;
        color: white;
      }
    }
  }
}

#map_bg_menu button {
  order: 2;
}

#map_bg_menu li {
  justify-content: flex-end;
}

#map_controls {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 99;
  border-radius: 4px;
  overflow: hidden;
}

#map_controls button, #location_controls button {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background-color: white;
  color: #00A6EE;
  border: none;
  cursor: pointer;
  font-size: 24px;
}

#map_controls button:hover, #location_controls button:hover, #location_controls button.active {
  background-color: #00A6EE;
  color: white;
}

#map_controls hr {
  margin: 0 0 -1px 0;
  border-top: 1px solid #00A6EE;
}

#location_controls {
  position: absolute;
  top: 120px;
  right: 20px;
  z-index: 99;
  border-radius: 4px;
  overflow: hidden;
}

#amp_list{
  position: absolute;
  z-index: 99;
  top: 144px;
  right: 20px;
}

ul#amp_menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

#amp_menu  span {
  margin-right: 10px;
}

#amp_menu button {
  order: 2;
}

#amp_menu li {
  justify-content: flex-end;
}

#map_bg_list {
  position: absolute;
  z-index: 99;
  bottom: 14vh;
  right: 20px;
}

ul#map_bg_menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

//#timeline_commands, #timeline_viewport, #mobile_timeline {
//  position: absolute;
//}

#timeline {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  min-height: 60px;
  z-index: 100;
  display: flex;

  &.weather_details {
    //transform: scale(0.55, 0.55) translateY(calc(45% - 20px));
    //left: calc(100vw * (1 - 0.55) / (-2 * 0.55));
    //right: calc(100vw * (1 - 0.55) / (-2 * 0.55));

    div#data_info, div#wind_quick_legend {
      display: none;
    }

    #timeline_viewport {
      //flex: 0 0 calc((100vw - 256px) / 0.55);

      // Note : empiric
      #timeline_content {
        //padding-left: calc((100vw * (1 - 0.55) / (2 * 0.55)) - 200px);

        #weather_details {
          display: flex;
        }
      }

      #timeline_steps {
        border-bottom-width: 0;
        width: 6480px;

        .tl_item {
          width: 5px;
          min-width: 5px;
          height: 50px;
          margin-bottom: -3px;

          &[data-rt] {
            border-bottom-width: 0;
          }

          &[data-fc]::after {
            font-size: .7em;
            left: -13px;
            text-align: center;
            height: 20px;
            width: 30px;
            line-height: 20px;
          }

          &[data-day]:before {
            bottom: 30px;
          }
        }
      }
    }

    #timeline_commands {
      #weather_details_header {
        display: block;
      }
    }

    #timeline_ts {
      #timeline_date {
        //left: 260px;
        //font-size: 20px;
        //width: 120px;
      }

      #timeline_hour {
        //left: calc((100vw * (1 - 0.55) / (2 * 0.55)) + 50vw - 38px);
        //width: 72px;
        //font-size: 20px;
      }

      #timeline_cursor {
        //left: calc((100vw * (1 - 0.55) / (2 * 0.55)) + 50vw - 1px);
        //width: 3px;
        height: 800px;
        opacity: 0.33;
      }
    }

    #timeline_info {
      #weather_buttons {
        display: block;
      }
    }
  }
}

#timeline_commands {
  //bottom: 48px;
  flex: 0 0 216px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  //width: 184px;
  padding: 4px 8px 0;
  //background-color: white;
  //left: 0;
  //bottom: 0;
  //z-index: 101;
  //position: absolute;

  //& > * {
  //  display: flex;
  //  flex: 1 0 auto;
  //}

  //#step_backward, #step_forward {
  //  display: none;
  //}

  button {
    //border: 1px solid white;
    //border-radius: 50%;
    border: none;
    width: 36px;
    height: 36px;
    background-color: transparent;
    color: #154194;
    //padding: 5px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
      color: white;
      background-color: #154194;
    }
  }
}

#timeline_ts {
  display: inline-block;
  //position: absolute;
  //bottom: 0;
  //left: 0;
  //right: 0;
  //z-index: 98;
  //height: 60px;

  #timeline_date {
    position: absolute;
    left: 260px;
    top: 5px;
    width: 80px;
    font-size: 11px;
    display: none;
  }

  #timeline_hour {
    //text-transform: uppercase;
    position: absolute;
    bottom: 0;
    left: calc(50vw - 42px);
    background-color: #154194;
    color: white;
    padding: 4px;
    width: 76px;
    font-size: 12px;
    text-align: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  #timeline_cursor {
    width: 2px;
    height: 60px;
    position: absolute;
    top: -2px;
    left: calc(50vw - 1px);
    background-color: #154194;
    z-index: 98;
  }
}

#timeline_viewport {
  flex: 0 0 calc(100vw - 256px);
  position: relative;
  //position: absolute;
  //bottom: 0;
  //right: 0;
  //left: 200px;
  overflow-x: hidden;
  overflow-y: visible;
  min-height: 60px;
  //background-color: white;
  //z-index: 99;

  #timeline_steps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px inset #00A6E7;
    width: 12960px;

    &:hover {
      cursor: grab;
    }

    //&:after {
    //  position: absolute;
    //  top: 4px;
    //  content: '';
    //  width: 100%;
    //  height: 1px;
    //  background-color: rgba(255, 255, 255, 0.5);
    //  z-index: -1;
    //}

    .tl_item {
      width: 10px;
      min-width: 10px;
      height: 56px;
      //border-radius: 50%;
      //opacity: 0;
      margin-bottom: -2px;
      position: relative;

      &[data-rt] {
        border-bottom: 2px solid #154194;
      }

      &:hover {
        background-color: #e0eef4;
      }
    }

    //.tl_item[data-fc] {
    //  border-bottom: 2px solid #00A6E7;
    //  opacity: 1;
    //  position: relative;
    //}

    //.tl_item[data-rt][data-fc] {
      //border: 2px solid #FFF;
      //background-color: #00A6E7;
      //width: 5px;
      //height: 5px;
    //}

    .tl_item[data-fc]:after {
      content: attr(data-fc);
      position: absolute;
      bottom: 1px;
      left: -24px;
      font-size: 13px;
      width: 60px;
      height: 24px;
      line-height: 24px;
      text-align: center;
    }

    .tl_item[data-fc='21h'], .tl_item[data-fc='22h'], .tl_item[data-fc='23h'], .tl_item[data-fc='00h'], .tl_item[data-fc='01h'],
    .tl_item[data-fc='02h'], .tl_item[data-fc='03h'], .tl_item[data-fc='04h'], .tl_item[data-fc='05h'], .tl_item[data-fc='06h'] {
      &:after {
        background-color: #e0eef4;
        color: #333333;
      }
    }


    .tl_item[data-day]:before {
      content: attr(data-day);
      position: absolute;
      bottom: 36px;
      left: -6px;
      width: 64px;
      font-size: 11px;
    }
  }
}

//#mobile_timeline {
//  bottom: 24px;
//}
//
//#mobile_timeline .rangeSlider__fill {
//  background: none;
//  box-shadow: none;
//}
//
//#mobile_timeline .rangeSlider__buffer {
//  height: 10px;
//  top: 5px;
//  background: white;
//}
//
//#mobile_timeline .rangeSlider {
//  background: #00A6E7;
//  border: none;
//  box-shadow: none;
//}

img.pointer_direction {
  width: 22px;
}

#timeline_info {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 0;
}

#wind_quick_legend {
  position: absolute;
  right: 13px;
  top: -198px;
  font-size: 10px;
  text-align: center;
  width: 48px;
  padding: 4px;
  display: none;

  & > div:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  & > div:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

#map_container.wind #wind_quick_legend {
  display: block;
}

#data_info {
  position: absolute;
  z-index: 99;
  right: 0;
  bottom: 0;
  padding: 4px 10px;
  max-width: 320px;
  height: 28px;
  background-color: #fffc;
  border-radius: 8px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #154194;

  & > div {
    flex: 1 1 auto;

    &:first-child:last-child {
      flex: 0 0 100%;
    }
  }

  & > div:not(:first-child) {
    flex: 2 1 auto;

    & > p {
      padding-left: 10px;
      border-left: 1px solid #154194;;
    }
  }

  & > div:first-child:not(:last-child) {
    padding-right: 10px;
  }

  p {
    margin: 0;
    font-size: 10px;
    line-height: 1.1;

    em {
      font-size: 10px;
    }
  }
}

#weather_buttons {
  display: none;
  position: absolute;
  top: -36px;
  right: 0;
  padding: 6px 4px;
  border-radius: 8px 0 0 0;
  background-color: rgba(255, 255, 255, 0.8);

  button {
    border-radius: 4px;
    padding: 6px 10px;
    font-size: 12px;
    border: none;
    margin: 0 4px;
    min-width: 36px;
    height: 24px;
    color: #154194;

    span {
      padding-left: 6px;
    }

    &:hover {
      cursor: pointer;
    }

    &.is_favorite {
      background-color: #154194;
      color: white;
    }
  }
}

#map_container.wind_fc, #map_container.waves_fc, #map_container.weather_fc,  #map_container.wind, #map_container.amp {
  #data_info {
    display: flex;
  }
}

//#timeline_content .tl_item[data-rt]:hover, #timeline_content .tl_item[data-fc]:hover {
//  cursor: pointer;
//  background-color: #F7A600;
//}
//
//#map_container #timeline_content .tl_item.active, #map_container #timeline_content .tl_item.active:hover {
//  background-color: red;
//}

#static_canvas, #live_canvas, #forecasts, #measures, #meteogramme_wrapper, #map {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
}

#coast_line {
  background-color: #0F73A9;
  z-index: 3;
  display: none;
}

#map_container.wind_fc.rendered {
  .leaflet-tile-pane > .leaflet-layer {
    z-index: 2 !important;
  }

  #map {
    background-color: transparent;
  }
}

#map_container.waves_fc.rendered {
  .leaflet-tile-pane > .leaflet-layer {
    z-index: 3 !important;
  }
}

#map_container.wind_fc.rendered #coast_line, #map_container.waves_fc.rendered #coast_line {
  background: none;
}

#map_container.waves_fc.rendered #live_canvas {
  z-index: 2;
}

#static_canvas {
  z-index: 2;
}

.fc_colors {
  filter: url(#bgFilter);
}

#map {
  z-index: 6;
  width: 100%;
  height: 100%;
  background-color: #0F73A9;
}

#particles_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
}

#waves_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

#map .mnt_layer {
  z-index: 201 !important;
}

#forecasts {
  z-index: 2;
}

#measures {
  z-index: 5;
  cursor: pointer;
}

#map_legend {
  padding: 20px 10px;
  font-size: 12px;
  color: #154194;
}

#map_legend h4 {
  margin: 5px 0 8px 0;
  //text-transform: uppercase;
  //text-align: center;
}

#map_legend {
  #wind_types_legend, #sources_legend {
    padding: 0 10px;

    & > div {
      margin-bottom: 5px;
    }

    & > p {
      margin: 0;
    }
  }
}

#wind_types_legend img {
  width: 16px;
  transform: rotate(-90deg)
}

#news_legend {
  text-align: center;

  img {
    width: 50%;
    max-width: 100px;
    height: auto;
  }
}

#map_legend table {
  text-align: center;
  border-collapse: inherit;
  table-layout: fixed;
  color: #154194;
  margin: auto;
  width: 100%;
  border-spacing: 10px 0;
}

#map_legend thead tr th {
  color: #154194;
  //text-transform: uppercase;
  text-align: center;
  padding-bottom: 10px;
}

#map_legend table th, #map_legend table td {
  //padding: 2px;
}

#map_legend tbody tr:first-child td {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

#map_legend tbody tr:last-child td {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

#map_legend tbody tr:nth-child(6),
#map_legend tbody tr:nth-child(7),
#map_legend tbody tr:nth-child(8),
#map_legend tbody tr:nth-child(9) {
  color: white;
}

#map_legend table + div {
  display: flex;
  padding: 10px;
  justify-content: space-around;
}

//#map_legend .legend_close {
//  display: flex;
//  justify-content: flex-end;
//  padding: 10px 13px 0 0;
//}
//
//#map_legend .legend_close button:hover {
//  cursor: pointer;
//}

#meteogramme_wrapper {
  display: none;
  z-index: 5;
}

#meteogramme_wrapper.active {
  display: block;
}

#measures .fcc rect {
  fill-opacity: 0;
  cursor: pointer;
}

//#map_click_details circle {
//  fill: #0F73A9;
//  stroke: #154194;
//}
//
//#map_click_details p {
//  text-align: center;
//  margin: 0;
//  border: 1px solid #154194;
//  border-radius: 0 10px 10px;
//  font-size: 12px;
//}



.wind_details body, .weather_poi body,  .webcam_spot body, .temp_sensor body, .location_spot body, .tide_spot body ,.amp_spot body,
#map_click_details body, .wave_sensor body, .water_temp_sensor body {
  background-color: transparent;
  position: static;
}

.wind_details p, .temp_sensor p, .water_temp_sensor p {
  margin: 0;
  font-size: 12px;
  color: #154194;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
}

.wind_details p > * {
  width: 50%;
  text-align: center;
  display: inline-block;
  padding: 3px 4px;
}

.wind_details p > :first-child {
  padding-left: 6px;
}

.wind_details p > :last-child {
  padding-right: 6px;
}

.wind_sensor {
  fill: white;
  color: white;

  .devent {
    fill: #A4B4CD;
  }
}

.wind_sensor .sensor_name {
  transform: translate(-10px, 26px);
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
}

.wave_sensor .sensor_name {
  transform: translate(-10px, 36px);
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  fill: white;
}

.water_temp_sensor .sensor_name {
  transform: translate(36px, 54px);
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  fill: white;
}

#map_container.wind_fc .wind_details p {
  border: 1px solid  #154194;
}

#map_container.wind_fc .wind_sensor {
  stroke: transparent;
}

.temp_sensor p, .water_temp_sensor p {
  background-color: white;
  padding: 3px 4px;
  justify-content: center;
}

.water_temp_sensor p {
  color: #00A6E7;
  border: 1px solid #00A6E7;
}

.hide_inactive .weather_poi:not(.poi-active) {
  display: none;
}

.weather_poi .poi_icon {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 16px;
  color: white;
  font-weight: 500;
  padding: 16px 2px 2px 2px;
  text-decoration: none;
}


.poi-active {
  body {
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 4px;

    p {
      display: block;
      width: 100%;
      line-height: 1;
      font-size: 10px;
      color: #154194;
      text-align: center;
      margin: -10px 0 10px 0;
      padding: 0 0 10px 0;
    }
    span{
      color: #154194;
    }
  }

}

.weather_poi img {
  width: 36px;
}

.weather_poi p {
  width: 100%;
  line-height: 1;
  font-size: 10px;
  color: #154194;
  text-align: center;
  display: none;
  margin: -10px 0 10px 0;
  padding: 0 0 10px 0;
}

.weather_poi:hover p, .weather_poi.active p {
  display: block;
}

.weather_poi:hover body, .weather_poi.active body {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 4px;
}

.weather_poi.active .poi_icon, .weather_poi.active p {
  color: #154194;
}

.wave_sensor .waves .capteur_nom {
  display: none;
}

.wave_sensor .swell_data {
  display: flex;
  height: 32px;
  align-items: center;
  text-decoration: none;
  background-color: transparent;

  .swell_height, .swell_period, .swell_temp {
    background-color: white;
    padding: 4px;
    line-height: 12px;
    color: #154194;
    font-size: 12px;
  }

  .swell_height {
    border-radius: 12px 0 0 12px;
    padding-left: 8px;
  }

  .swell_temp {
    border-radius: 0 12px 12px 0;
    padding-right: 8px;
  }

  & > div span {
    font-size: 8px;
    display: block;
  }
}

.wave_sensor img {
  width: 32px;
}

.webcam_spot img {
  width: 28px;
  background-color: white;
  border: 1px solid #00A6EE;
  border-radius: 10px;
}

.tide_spot img {
  width: 28px;
  background-color: #00A6EE;
  border: 1px solid white;
  border-radius: 10px;
}

.amp_spot img {
  width: 28px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 10px;
}

.amp_zone{
  fill: rgba(224,87,247, 0.5);
  stroke: rgba(224,87,247, 1);
}
.location_spot i {
  font-size: 32px;
  width: 32px;
  height: 32px;
  color: #154194;
}

#shom_wrapper {
  width: 675px;
  height: 910px;
  border: none;
  -ms-overflow-style: none;
  overflow: auto;
}

#wm_logo_container {
  display: flex;
  position: absolute;
  top: 13px;
  left: 80px;
  right: 80px;
  align-items: center;
  justify-content: center;
  z-index: 99;

  #wm_logo {
    display: block;
    padding: 10px 16px;
    background-color: white;
    border-radius: 25px;
    height: 24px;
  }

  #map_favorite {
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      color: white;
      background-color: #00A6EE;
      font-size: 9px;
      width: 44px;
      height: 44px;
      border: none;
      border-radius: 50%;
      margin-left: 5px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .no-favorite {
    background-color: rgb(255, 1, 158) !important;
  }
}

body.windmorbihan {
  #wm_logo {
    img {
      width: 120px;
    }
  }
}

body.windloireatlantique {
  #wm_logo {
    img {
     width: 160px;
    }
  }
}

body.prod {
  .leaflet-control-locate {
    display: none;
  }
}

#map_wrapper {
  .leaflet-popup-content-wrapper {
    border-radius: 10px;
    padding: 0;
    overflow: hidden;
    border: 2px solid white;

    .leaflet-popup-content {
      margin: 0 !important;

      & > p {
        margin: 0;
        padding: 3px 8px;
      }

      .pointer_info {
        width: 85px;
      }
    }
  }

  .leaflet-popup-tip {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    padding: 0;
    width: 2px;
  }

  a.leaflet-popup-close-button {
    padding-top: 6px;
    padding-left: 4px;
    color: white;
  }

  .leaflet-popup-content-wrapper, .leaflet-popup-tip {
    box-shadow: none;
  }
}
