@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Regular.woff2') format('woff2'),
  url('../fonts/Montserrat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Italic.woff2') format('woff2'),
  url('../fonts/Montserrat-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Bold.woff2') format('woff2'),
  url('../fonts/Montserrat-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-BoldItalic.woff2') format('woff2'),
  url('../fonts/Montserrat-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Light.woff2') format('woff2'),
  url('../fonts/Montserrat-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-LightItalic.woff2') format('woff2'),
  url('../fonts/Montserrat-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

body {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
  background-color: white;
}

a:focus, button:focus {
  outline: none;
}

.hidden {
  display: none;
}

.loading {
  text-align: center;
  padding: 20px;
  color: #154194;
  font-weight: 300;
}

#toggle_side_menu {
  position: absolute;
  z-index: 999;
  top: 13px;
  right: 13px;
  border: none;
  background-color: white;
  //width: 84px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: #154194;
  transition: right ease-out 0.5s;
  padding: 0 12px;

  i {
    display: flex;
    justify-content: center;
    margin: 0 4px 0 0;

    &:before {
      content: '\f0c9';
      font-size: 16px;
    }

  }

  span {
    font-size: 16px;
    font-family: Montserrat, Helvetica, Arial, sans-serif;
  }

  &.active {
    right: 273px;

    i {
      margin: 0;

      &:before {
        content: '\f00d';
      }
    }

    span {
      display: none;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &.active + #side_menu {
    width: 260px;
  }
}

#side_menu {
  position: absolute;
  z-index: 101;
  top: 0;
  bottom: 60px;
  right: 0;
  width: 0;
  transition: width ease-out 0.5s;
  background-color: white;

  #side_tabs {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #154194;

    button {
      border: none;
      display: flex;
      flex-direction: column;
      flex: 1 0 33.33%;
      align-items: center;
      justify-content: center;
      color: #154194;
      background-color: #FFF;
      padding: 16px 12px 0;
      line-height: 32px;

      &.active {
        border-bottom: 1px solid #154194;
      }

      span {
        font-weight: 300;
        font-size: 12px;
      }

      &:hover {
        cursor: pointer;
        background-color: #EFEFEF;
      }
    }

    &[data-active='layers'] ~ #layers_tab {
      display: flex;
    }

    &[data-active='legend'] ~ #legend_tab {
      display: block;
    }

    &[data-active='links'] ~ #links_tab {
      display: block;
    }
  }

  .tab_content {
    display: none;
    height: calc(100vh - 278px);
    min-height: 364px;
    overflow-y: scroll;
    overflow-x: hidden;
    align-items: flex-start;
  }

  #layers_tab {
    flex-wrap: wrap;
    padding: 16px;
    gap: 0 8px;

    h3 {
      color: #39A9E0;
      flex: 1 0 100%;
      margin: 4px;
      font-weight: normal;
      font-size: 13px;
      line-height: 13px;
      width: 100%;

      &:not(:first-child) {
        margin-top: 16px;
      }
    }

    .layer_preview {
      width: calc(50% - 4px);
      background-color: transparent;
      text-align: left;
      border: none;

      img {
        border: 2px solid white;
        padding: 2px;
        max-width: 100%;
        border-radius: 12px;
        height: 72px;
        object-fit: cover;
        object-position: center;
      }

      span {
        color: #154194;
        font-weight: normal;
        font-size: 12px;
      }

      &:hover {
        cursor: pointer;
      }

      &.active img {
        border: 2px solid #154194;
      }
    }
  }

  #links_tab {
    padding: 16px;

    & > div:first-child {
      display: grid;
      grid-template-columns: calc(50% - 5px) calc(50% - 5px);
      grid-column-gap: 10px;
      grid-row-gap: 10px;

      img {
        max-width: 100%;
      }
    }

    & > div:last-child {
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      a {
        color: #154194;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 12px;

        img {
          height: 24px;
          width: auto;
        }
      }
    }
  }
}

body.active_poi {
  #side_menu {
    bottom: 0;

    .tab_content {
      height: calc(100vh - 218px);
    }
  }
}

#footer {
  padding: 0 20px 10px;
  margin-top: 8px;
}

#partners a {
  display: inline-block;
  width: 100%;

  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}

#footer-partner {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  margin-top: 20px;
}

body.windmorbihan {
  #footer-partner .wla {
    display: none;
  }
}

body.windloireatlantique {
  #footer-partner .wm {
    display: none;
  }
}

#footer-columns {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  max-width: 1140px;

  & > a {
    height: 60px;
  }
}

.img_logo img {
  object-fit: contain;
  height: 60px;
  max-width: 90px;
}

#footer hr {
  width: 240px;
  border: none;
  margin: 10px auto;
  height: 2px;
  background-color: #39A9E0;
}

#footer_info {
  margin-bottom: 40px;
  font-size: smaller;
}

#footer_info p {
  margin-top: 15px;
  text-align: center;
}

#footer_info p a {
  color: #000000;
  text-decoration: none;
}

#wm_links {
  max-width: 960px;
  padding: 20px;
  margin: 120px auto;

  h1 {
    color: #154194;
  }

  a {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    align-items: center;
    color: black;

    img {
      width: 60px;
      height: auto;
      margin-right: 20px;
    }
  }
}

#new_contact {
  margin: 120px auto 40px auto;
  width: 600px;
  padding: 1em;
  border: 1px solid #39A9E0;
  border-radius: 1em;

  & > div + div {
    margin-top: 1em;
  }

  label {
    display: inline-block;
    width: 90px;
    text-align: right;
  }

  input, textarea {
    font: 1.1em sans-serif;
    width: 400px;
    box-sizing: border-box;
    border: 1px solid #999;
    border-radius: 4px;
    padding: 4px 8px;
  }

  input:focus, textarea:focus {
    border-color: #000;
  }

  textarea {
    vertical-align: top;
    height: 20em;
  }

  .form_button {
    background: #39A9E0;
    border: 4px solid #fff;
    border-radius: 10px;
    color: #fff;
    display: block;
    font-size: 1em;
    font-weight: bold;
    margin: 10px auto;
    padding: 1em 3em;
    position: relative;
    text-transform: uppercase;
  }

  h3 {
    color: #39A9E0;
    font-weight: bold;
    font-style: normal;
    text-transform: uppercase;
    text-align: center;
  }

  .submit_msg {
    text-align: center;
  }
}

.logo_contact {
  width: 220px;
  height: auto;
  margin-left: -170px;
  margin-top: -40px;
}

